const INITIAL_STATE = {
	registro: {},
	modoTela: 'lista',
	aguardando: false,
	lista: [],
	listaConfirmacao: [{
		id: false,
		valor: 'Não'
	}, {
		id: true,
		valor: 'Sim'
	}],
	listaOpcoes: [],
	listaCliente: [],
	listaFormaPagamento: [],
	filtro: {
		dataInicial: null,
		dataFinal: null,
		valor_pago: null
	},
	listaTotal:[],
};

export default (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case 'RESERVA_MODO_TELA':
			return {
				...state,
				modoTela: action.payload.modo,
				registro: action.payload.registro
			};

		case 'RESERVA_LISTADO':
			return {
				...state,
				modoTela: 'lista',
				lista: action.payload.data
			};



		case 'RESERVA_SET_AGUARDANDO':
			return {
				...state,
				aguardando: action.payload
			};

		case 'RESERVA_OPCOES_VIAGEM_SELECT_LISTADO':
			return {
				...state,
				listaOpcoes: action.payload.data
			};
		case 'RESERVA_BUSCAR_CLIENTE_SELECT_LISTADO':
			return {
				...state,
				listaCliente: action.payload.data
			};
		case 'RESERVA_FORMA_PAGAMENTO_SELECT_LISTADO':
			return {
				...state,
				listaFormaPagamento: action.payload.data
			};
		case 'RESERVA_CLIENTE_SELECT_LISTADO':
			return {
				...state,
				listaCliente: action.payload.data
			};
		case 'RESERVA_FILTRO':
			return { ...state, filtro: action.payload };

		case 'RESERVA_TOTAL_LISTADO':
		console.log(action.payload.data);
			return {
				...state,
				listaTotal: action.payload.data
			};
		default:
			return state;
	}
}