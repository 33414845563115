import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { reduxForm, Field, formValueSelector, getFormValues } from 'redux-form';

import Content from '../common/template/content';
import ContentCard from '../common/template/contentCard';
import ContentCardHeader from '../common/template/contentCardHeader';
import ContentCardBody from '../common/template/contentCardBody';
import ContentCardFooter from '../common/template/contentCardFooter';
import Row from '../common/layout/row';
import Grid from '../common/layout/grid';
import Button from '../common/button/button';
import Form from '../common/form/form';
import LabelAndInput from '../common/form/labelAndInput';
import LabelAndInputNumber from '../common/form/labelAndInputNumber';
import LabelAndInputMask from '../common/form/labelAndInputMask';
import LabelAndInputDateTime from '../common/form/labelAndInputDateTime';
import Select from '../common/form/select';
import SelectAlteracao from '../common/form/selectAlteracao';
import LabelAndCheckbox from '../common/form/labelAndCheckbox';
import ColorSelector from '../common/form/colorSeletor';
import Table from '../common/table/table';
import THead from '../common/table/tHead';
import Tr from '../common/table/tr';
import Th from '../common/table/th';
import TBody from '../common/table/tBody';
import Td from '../common/table/td';

import {
    setModoTela, initForm, buscarReserva
} from './reservaActions';
import { selectTab } from '../common/tab/tabActions';
import labelAndInput from '../common/form/labelAndInput';
import labelAndInputNumber from '../common/form/labelAndInputNumber';

class ReservaForm extends Component {

    state = {

    }

    componentWillMount() {

    }



    render() {

        let readOnly = this.props.excluir ? 'readOnly' : '';

        let listaCliente = [];

        this.props.listaCliente.forEach(item => {
            listaCliente.push({
                ...item,
                key: `${item.id}-1`
            })
            if ((item.titular2 || '').trim()) {
                listaCliente.push({
                    ...item,
                    key: `${item.id}-2`,
                    nome: `${item.nome} - Segundo Titular ${item.titular2}`
                })
            }
            if ((item.dependente1 || '').trim()) {
                listaCliente.push({
                    ...item,
                    key: `${item.id}-3`,
                    nome: `${item.nome} - Dependente ${item.dependente1}`
                })
            }
            if ((item.dependente2 || '').trim()) {
                listaCliente.push({
                    ...item,
                    key: `${item.id}-4`,
                    nome: `${item.nome} - Dependente ${item.dependente2}`
                })
            }
            if ((item.dependente3 || '').trim()) {
                listaCliente.push({
                    ...item,
                    key: `${item.id}-5`,
                    nome: `${item.nome} - Dependente ${item.dependente3}`
                })
            }
            if ((item.dependente4 || '').trim()) {
                listaCliente.push({
                    ...item,
                    key: `${item.id}-6`,
                    nome: `${item.nome} - Dependente ${item.dependente4}`
                })
            }
            if ((item.dependente5 || '').trim()) {
                listaCliente.push({
                    ...item,
                    key: `${item.id}-7`,
                    nome: `${item.nome} - Dependente ${item.dependente5}`
                })
            }
        });
        

        return (
            <ContentCard>

                <Form event={this.props.handleSubmit}>
                    <ContentCardBody>
                        {!this.props.formularioValues.id_cliente ?
                            <>
                                <Row>


                                    <LabelAndInput
                                        name='buscar'
                                        label='Buscar'
                                        placeholder='Informe o nome, número do contrato, número do convite, CPF, dependentes'
                                        cols='6 6 6 6'
                                        value={this.state.buscar}
                                        onChange={event => {
                                            console.log(event);
                                            this.setState({
                                                ...this.state,
                                                buscar: event.target.value
                                            })
                                        }}
                                    />

                                    <Button
                                        style={{ marginTop: 25 }}
                                        text='Buscar'
                                        type={'info'}
                                        icon={'fa fa-search'}
                                        cols='6 6 6 6'
                                        event={() => this.props.buscarReserva(this.state.buscar)}
                                    />


                                </Row>
                                <Table>
                                    <THead>
                                        <Th></Th>
                                    </THead>
                                    <TBody>
                                        {(listaCliente || []).map(item => (

                                            <Tr key={item.key} className={`${this.state.linhaSelecionada == item.id ? 'hover' : ''}`}
                                                onClick={() => this.props.initForm({ ...this.props.formularioValues, id_cliente: item.id, nome_cliente: item.nome, id_cliente_tipo: item.id_tipo, nome_vendedor: item.nome_vendedor, numero_convite: item.numero_convite, numero_contrato: item.numero_contrato })}
                                                onMouseEnter={() => this.setState({ ...this.state, linhaSelecionada: item.id })} >
                                                <Td>{item.nome}</Td>
                                            </Tr>
                                        ))}
                                    </TBody>
                                </Table>
                            </>
                            :
                            <Row>

                                <Field
                                    name='nome_cliente'
                                    component={LabelAndInput}
                                    label='Cliente'
                                    placeholder='Informe o cliente'
                                    cols='4 4 4 4'
                                    readOnly={'readOnly'} />

                                {!this.props.formularioValues.numero_contrato ?

                                    <Field
                                        name='numero_convite'
                                        component={LabelAndInput}
                                        label='Convite'
                                        placeholder='Informe o convite'
                                        cols='4 4 4 4'
                                        readOnly={'readOnly'} />

                                    :
                                    <Field
                                        name='numero_contrato'
                                        component={LabelAndInput}
                                        label='Contrato'
                                        placeholder='Informe o contrato'
                                        cols='4 4 4 4'
                                        readOnly={'readOnly'} />                                                
                              }

                                <Field
                                    name='nome_vendedor'
                                    component={LabelAndInput}

                                    label='Vendedor'
                                    placeholder='Informe o vendedor'
                                    cols='4 4 4 4'
                                    readOnly={'readOnly'} />

                            </Row>
                        }
                        <Row>

                            <Field
                                name='contato'
                                component={LabelAndInputMask}
                                mask='(99) 99999-9999'
                                label='Contato'
                                placeholder='Informe o contato'
                                cols='12 4 4 4'
                                readOnly={readOnly} />

                            <Field
                                name='data_ida'
                                component={LabelAndInputMask}
                                mask='99/99/9999'
                                label='Data da Ida'
                                placeholder='Informe a data'
                                cols='12 4 4 4'
                                readOnly={readOnly} />

                            <Field
                                name='data_volta'
                                component={LabelAndInputMask}
                                mask='99/99/9999'
                                label='Data da Volta'
                                placeholder='Informe a data'
                                cols='12 4 4 4'
                                readOnly={readOnly} />

                            <Field
                                name='cidade'
                                component={LabelAndInput}
                                label='Cidade'
                                placeholder='Informe o cidade'
                                cols='12 4 4 4'
                                readOnly={readOnly} />

                            <Field
                                name='hotel'
                                component={LabelAndInput}
                                label='Hotel'
                                placeholder='Informe o hotel'
                                cols='12 4 4 4'
                                readOnly={readOnly} />

                            <Field
                                name='local_parceiro'
                                component={LabelAndInput}
                                label='Local / Parceiro'
                                placeholder='Informe local/parceiro'
                                cols='12 4 4 4'
                                readOnly={readOnly} />

                            <Field
                                name='valor_total'
                                component={LabelAndInputNumber}
                                label='Valor Total'
                                placeholder='Informe o valor total'
                                cols='12 4 4 4'
                                casas={2}
                                readOnly={readOnly} />

                            <Field
                                name='valor_pago'
                                component={LabelAndInputNumber}
                                label='Valor Pago'
                                placeholder='Informe o valor pago'
                                cols='4 4 4 4'
                                casas={2}
                                readOnly={readOnly} />

                            <Field
                                name='pessoas'
                                component={LabelAndInputNumber}
                                label='Pessoas'
                                placeholder='Informe a quantidade de pessoas'
                                cols='4 4 4 4'
                                casas={0}
                                readOnly={readOnly} />

                            <Field
                                name='observacao'
                                component={LabelAndInput}
                                label='Observação'
                                placeholder='Informe observações'
                                cols='12 12 12 12'
                                readOnly={readOnly} />

                        </Row>

                    </ContentCardBody>
                    <ContentCardFooter>
                        <Row alignCenter>
                            <Grid cols='6 4 3 1'>
                                {this.props.excluir ? (
                                    <Button
                                        text='Excluir'
                                        submit type={'danger'}
                                        icon={'fa fa-trash'} />
                                ) : (
                                    <Button
                                        text='Salvar'
                                        submit
                                        type={'success'}
                                        icon={'fa fa-check'} />
                                )}
                            </Grid>
                            <Grid cols='6 4 3 1'>
                                <Button
                                    text='Voltar'
                                    type={'warning'}
                                    icon={'fa fa-chevron-left'}
                                    event={() => this.props.setModoTela('lista')} />
                            </Grid>
                        </Row>
                    </ContentCardFooter>
                </Form>
            </ContentCard>
        )
    }


}

ReservaForm = reduxForm({ form: 'reservaForm', destroyOnUnmount: false })(ReservaForm);
const mapStateToProps = state => ({
    sessao: state.auth.usuario,
    usuarioLogado: state.auth.usuarioLogado,
    formularioValues: getFormValues('reservaForm')(state),
    registro: state.reserva.registro,
    listaConfirmacao: state.reserva.listaConfirmacao,
    listaOpcoes: state.reserva.listaOpcoes,
    listaFormaPagamento: state.reserva.listaFormaPagamento,
    listaCliente: state.reserva.listaCliente
});
const mapDispatchToProps = dispatch => bindActionCreators({
    setModoTela, initForm, buscarReserva
}, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(ReservaForm);
